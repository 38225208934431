/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

import '../scss/app.scss';

import $ from 'jquery';
global.moment = require('moment');
require('tempusdominus-bootstrap-4');
import 'jquery';
import 'bootstrap';
import 'bootstrap-select';
import 'summernote/dist/summernote-bs4.min.js';
import 'summernote/dist/lang/summernote-fr-FR.min.js';

$('[data-toggle="tooltip"]').tooltip(); //tooltip bootstrap
$('[data-toggle="popover"]').popover(); //popover bootstrap
$('.selectpicker').selectpicker({
    dropupAuto: false
}); //bootstrap select

$('.selectpicker_secteurs').change(function() {
    alert($(this).children("option:selected").text());
    $('#appliquer_cles_sousSecteur').find('.Mustard').show();
});

//Icons for tempus dominus calendar/time
$.fn.datetimepicker.Constructor.Default = $.extend({}, $.fn.datetimepicker.Constructor.Default, {
    icons: {
        time: 'far fa-clock',
        date: 'far fa-calendar',
        up: 'fas fa-arrow-up',
        down: 'fas fa-arrow-down',
        previous: 'fas fa-chevron-left',
        next: 'fas fa-chevron-right',
        today: 'fas fa-calendar-check-o',
        clear: 'fas fa-trash',
        close: 'fas fa-times'
    }
});

$(".alert").fadeTo(3000, 500).slideUp(500, function () { //fade alert message
    $(".alert").slideUp(500);
});

$('.summernote').summernote({ // Style for textarea
    lang: 'fr-FR',
    height: 180,
    toolbar: [
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['color', ['color']],
    ]
});

$('.datetimepicker').datetimepicker({ //Style for date picker
    locale: 'fr',
});

$('.timepicker').datetimepicker({ //Style for time picker
    locale: 'fr',
    format: 'LT',
});

$('.datepicker').datetimepicker({ //Style for date picker
    locale: 'fr',
    format: 'L',
});

//Hide Datepickers when outside click.
$("body").click(function () {
    var container = $('.bootstrap-datetimepicker-widget');
    container.hide(true);
    console.log(container);
});